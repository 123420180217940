<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="overflow: hidden !important;">
        <div id="popBody" class="pa-0 popBody">
            <table class="">
                <tr>
                    <td  @click="clickPop(popInfo)" style="cursor: pointer;">
                        <template v-if="popInfo.popDataType == 0">
                            <div class="">
                                <v-img
                                        :src="popInfo.popImg"
                                        :lazy-src="popInfo.popImg"
                                        class="grey lighten-2"

                                ></v-img>
                            </div>
                        </template>
                        <template v-else-if="popInfo.popDataType ==1">
                            <div id="" class="cont">
                                <div class="ql-editor">
                                    <span v-html="popInfo.popContent"></span>
                                </div>
                            </div>
                        </template>
                    </td>
                </tr>
            </table>
        </div>
        <div class="pa-0 popBottom">
            <table>
                <tr style="background-color: #eaeaea" align="right">
                    <td>
                        <!--<input type="checkbox" id="todaycloseyn" value="Y">-->
                        <input type="checkbox" :id="'check'+popInfo.popId" value="Y">
                        <span class="mx-3">하루동안 창을 표시하지 않음</span>
                        <!--<a href="#" @click="closeLayer('layerPop')" class="mx-3">닫기</a>-->
                        <a href="#" @click="closeWin(popInfo.popId)" class="mx-3">닫기</a>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PopRead',
        components: {},
        data: () => ({

            popId: "",
            popInfo: {},


        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));
            this.popId = this.$getParameterByName('popId');
            this.getData();


        },
        methods: {
            getData() {
                return this.$store.dispatch("pop/getPopInfo", {popId: this.popId})
                    .then((resp) => {
                        setTimeout(() => {

                            this.popInfo = resp.message;
                            this.setStyle()

                        }, 300)
                    })
                    .catch((err) => {
                    })

            },
            setStyle(){
                var pop = document.getElementById('popBody');

                pop.style.display = "block";

                if(this.popInfo.popWidth == 0) {
                    pop.style.width = "100%";
                }else {
                    pop.style.width = this.popInfo.popWidth + "px";
                }

                pop.style.height = (Number(this.popInfo.popHeight) - 30) + "px";

                if (this.popInfo.popScroll === 'true') {
                    pop.style.overflow = 'auto';
                } else {
                    pop.style.overflow = 'hidden';
                }

            },
            setCookie(cname, cvalue, exdays) {
                var todayDate = new Date();
                todayDate.setTime(todayDate.getTime() + (exdays*24*60*60*1000));
                var expires = "expires=" + todayDate.toUTCString(); // UTC기준의 시간에 exdays인자로 받은 값에 의해서 cookie가 설정 됩니다.
                document.cookie = cname + "=" + cvalue + "; " + expires+";path=/";
            },
            closeWin(key) {
                if ($("#check"+key).prop("checked")) {
                    this.setCookie('layerPop' + key, 'Y', 7);

                }
                window.close();
            },
            clickPop(val) {
                if (val.popLink != undefined && val.popLink.charAt(0) === '/') {
                    switch (val.popLinkType) {
                        case "0" : { //새창
                            window.open(val.popLink);
                            break;
                        }
                        case "1" : { //현재창
                            window.open(val.popLink);
                            //location.href = val.popLink;
                            break;
                        }
                        case "2" : { //메인창?
                            window.open(val.popLink);
                            //location.href = val.popLink;
                            break;
                        }
                    }
                }
            }
        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {
        },
    }
</script>

<style>

    html{
        overflow: hidden !important;
    }

</style>
